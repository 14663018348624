import React from 'react';
import { NavLink } from 'react-router-dom';
import './Navbar.css'; // Make sure this path matches your file structure

const Navbar = () => {
    return (
        <nav>
             <div className="navbar-logo">
                <span className="logo-red">VITALISYSHAK</span> Tech LLP
            </div>
            <ul>
                <li><NavLink exact to="/" activeClassName="active">Home</NavLink></li>
                <li><NavLink to="/about" activeClassName="active">About</NavLink></li>
                <li><NavLink to="/partners" activeClassName="active">Partners</NavLink></li>
                <li><NavLink to="/projects" activeClassName="active">Projects</NavLink></li>
                <li><NavLink to="/contact" activeClassName="active">Contact</NavLink></li>
            </ul>
        </nav>
    );
};

export default Navbar;
