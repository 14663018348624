import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import Home from './components/Home/Home';
import About from './components/About/About';
import Partners from './components/Partner/Partners';
import Project from './components/Project/Project';
import Contact from './components/Contact/Contact';
function App() {
    return (
        <Router>
            <div id="root">
                <Navbar />
                <main>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/about" element={<About />} />
                        <Route path="/partners" element={<Partners />} />
                        <Route path="/projects" element={<Project />} />
                        <Route path="/contact" element={<Contact />} />
                    </Routes>
                </main>
                <Footer />
            </div>
        </Router>
    );
}


export default App;
