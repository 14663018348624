import React from 'react';
import './Partners.css'; // Ensure to link your CSS file

// Import images
import amanImg from '../aman.png'; // Adjust the path as necessary
import krishImg from '../krish.jpg'; // Adjust the path as necessary
import harshImg from '../harsh.jpg'; // Adjust the path as necessary

const partners = [
  {
    id: 1,
    name: 'Aman Dutt Mathur',
    title: 'CEO',
    description: 'As CEO, Aman drives innovation and strategic growth at VITALISYSHAK Tech LLP, transforming healthcare solutions.',
    imgSrc: amanImg // Use the imported image
  },
  {
    id: 2,
    name: 'Kana Ram Khatik',
    title: 'CTO',
    description: 'Kana Ram leads the development of advanced technologies, ensuring the company stays at the forefront of emergency response systems.',
    imgSrc: krishImg // Use the imported image
  },
  {
    id: 3,
    name: 'Harsh Raj',
    title: 'CFO',
    description: 'Harsh manages financial strategy and operations, guiding the company toward sustainable growth and success.',
    imgSrc: harshImg // Use the imported image
  }
];

const Partner = () => {
  return (
    <section id="partners">
      <h2 style={{color :"#ff4d4d"}}>Our Partners</h2>
      <div className="partner-container">
        {partners.map(partner => (
          <div className="partner" key={partner.id}>
            <div className="partner-circle">
              <img src={partner.imgSrc} alt={`Partner ${partner.id}`} />
            </div>
            <h3>{partner.title}</h3>
            <p>{partner.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Partner;
