import React from 'react';
import './Project.css';
import ProjectImg from '../project.png';
function Project() {
    return (
        <div className="project-page">
     
            <section className="main-content">
            <img src={ProjectImg} alt="About Us" className="project-image" />
                <p>Your immediate emergency response solution.</p>

                <div className="info-section">
                    <h3>Quick Response, Lifesaving Service</h3>
                    <p>Ambulance Now provide a real-time ambulance tracking and booking service that ensures immediate medical assistance , bridging the gap between emergencies and life-saving care . Ambulance Now revolutionizes emergency healthcare with an intuitive platform , empowering communities with rapid access to emergency care. Your immediate emergency response solution.</p>

                    <button className="request-btn">Request an Ambulance</button>
                </div>
            </section>

           
        </div>
    );
}

export default Project;
