import React from 'react';
import './Footer.css'; 

const Footer = () => {
    return (
        <footer className="footer"> {/* Add a class for styling */}
            <div>
                <p>&copy; 2024 VitalisysHAK. All rights reserved.</p>
                <div className="social-links">
                    <a href="https://facebook.com">Facebook</a> 
                    <a href="https://twitter.com">Twitter</a>  
                    <a href="https://instagram.com">Instagram</a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
