import React from 'react';
import './Home.css';
import aboutImage from '../partners.jpg'; // Import your image


const Home = () => {
    return (
        <div className="home">
            <section className="about-us">
                <div className="about-content">
                    <img src={aboutImage} alt="About Us" className="about-image" />
                    <div className="about-text">
                        <h2>About Us</h2>
                        <p>
                            VITALISYSHAK Tech LLP is a dynamic, tech-driven firm at the intersection of healthcare and innovation.
                            Our mission is to provide life-saving, efficient, and technology-enhanced solutions. Starting with
                            real-time ambulance services, we aim to revolutionize on demand emergency response systems through advanced
                            tracking and dispatch technologies.
                        </p>
                        <a href="/about" className="learn-more">Learn More</a>
                    </div>
                </div>
            </section>

          
        </div>
    );
};

export default Home;
