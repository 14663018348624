import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyA-zkNe7scVw96cq9I6-WY2Sgnk4ffMl-I",
  authDomain: "vitalisyshak-23f89.firebaseapp.com",
  projectId: "vitalisyshak-23f89",
  storageBucket: "vitalisyshak-23f89.appspot.com",
  messagingSenderId: "383261310587",
  appId: "1:383261310587:web:0d66abbb56f701d083ecde"
};

// Initialize Firebase app
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

export { db };
