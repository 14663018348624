// frontend/src/components/About.js
import React from 'react';
import './About.css';


function About() {
    return (
        <div className="about-page">
            <h2 >About VitalisysHAK</h2>
            <p>
                Welcome to VitalisysHAK, a dynamic and forward-thinking company committed to making a difference in healthcare and emergency services. 
                We focus on innovative solutions that help save lives and improve the accessibility of on demand emergency medical services.
            </p>

            <h2>Our Mission</h2>
            <p>
                At VitalisysHAK, our mission is to revolutionize the emergency medical services industry through the use of technology. 
                We believe in leveraging modern tools to provide fast, reliable, and accessible medical transport for everyone. 
                Our flagship project, <strong>Ambulance Now</strong>, is a testament to this belief, offering quick ambulance booking and real-time tracking to ensure that help reaches you when it’s needed the most.
            </p>

            <h2>Ambulance Now Project</h2>
            <p>
                <strong>Ambulance Now</strong> is our most ambitious project to date. It is a mobile and web platform designed to facilitate the quick dispatch of ambulances, 
                just like booking a ride on Uber. With real-time tracking and a user-friendly interface, we are striving to make healthcare more responsive and accessible in emergencies.
            </p>
            <p>
                The project offers various types of ambulances for different medical needs, including medical emergencies, accidents, and more. 
                Whether it’s for a routine transport or a critical emergency, <strong>Ambulance Now</strong> ensures that help is just a tap away.
            </p>

            <h2>Our Partners</h2>
            <p>
                VitalisysHAK is proud to have a strong network of partners who share our vision for the future of healthcare and emergency services. 
                Each of our three partners brings a unique set of skills and expertise to the table, helping to make our vision a reality.
            </p>
            <ul>
                <li><strong>Partner 1:</strong> Specializes in medical technology and brings years of experience in healthcare innovation.</li>
                <li><strong>Partner 2:</strong> An expert in logistics and transport, ensuring that our ambulance services are efficient and reliable.</li>
                <li><strong>Partner 3:</strong> Focuses on business development and strategic growth, helping to expand our services to new regions.</li>
            </ul>

            <h2>Why Choose Us?</h2>
            <p>
                VitalisysHAK is dedicated to making a real difference in the world. Our team consists of experts from various fields who work together to develop and implement 
                solutions that save lives. By choosing VitalisysHAK, you are choosing a company that puts people first and uses the latest technology to make a positive impact.
            </p>

            <h2>Contact Us</h2>
            <p>
                If you want to learn more about our projects or how we can help you, feel free to <a href="/contact">get in touch</a>. We are always open to partnerships, collaborations, 
                and opportunities to improve the healthcare system.
            </p>
        
        </div>
    );
}

export default About;
